.root {
  display: flex;
  justify-content: center;
}

.container {
  width: 750px;
  height: 550px;
  background-color: var(--color-background);
  box-shadow: 1px 1px 5px 5px var(--color-background-filters);
  margin: var(--spacing-medium);
  overflow: auto;
}

:root {
  --color-primary: black;
  --color-background: rgb(255,255,255);
  --color-background-filters: rgb(252,232,230);
  --color-background-apartments: rgb(245,247,247);
  --color-title: rgb(24,142,236);
  --color-important: red;
  --color-warning: rgb(250,180,180);

  --spacing-atomic: 1px;
  --spacing-border: 2px;
  --spacing-tiny: 4px;
  --spacing-small: 8px;
  --spacing-medium: 16px;
  --spacing-large: 24px;
  --spacing-big: 48px;
  --spacing-huge: 64px;

  --font-tiny: 10px;
  --font-small: 12px;
  --font-medium: 16px;
  --font-large: 20px;
}

.header {
  display: flex;
  justify-content: center;
  padding: var(--spacing-small);
  font-size: var(--font-large);
  color: var(--color-title);
}

.content {
  font-size: var(--font-medium);
  color: var(--color-primary);
}
