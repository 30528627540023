.links {
    display: flex;
    align-items: center;
}

.link {
    display: flex;
    flex-direction: row;
}

.link-available {
}

.link-unavailable {
    background-color: var(--color-warning);
}

.link-image {
    display: flex;
    width: var(--spacing-large);
    height: var(--spacing-large);
}
