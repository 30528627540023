.price-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: var(--spacing-huge);
}

.price {
    color: var(--color-title);
}

.price-per-size {
    font-weight: bold;
}

.size {
    font-size: var(--font-small);
    font-weight: bold;
}
