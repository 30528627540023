.apartment {
    width: 336px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--spacing-tiny) 0px;
    border-style: groove;
    background-color: var(--color-background-apartments);
}

.details {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-around;
    padding-left: var(--spacing-tiny);
    padding-right: var(--spacing-small);
}

.row {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    word-break: break-all;
}

.street {
    font-size: var(--font-medium);
}

.structure {
    font-size: var(--font-small);
}

.floors {
    font-size: var(--font-small);
}

.floor {
    font-weight: bold;
}

.spacer {
    width: var(--spacing-small);
}

.polygons {
    font-size: var(--font-tiny);
}

.create-date {
    font-size: var(--font-small);
    color: var(--color-important);
}
