.score {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-small);
    color: var(--color-important);

    border-left: var(--spacing-atomic);
    border-left-color: var(--color-primary);
    border-left-style: solid;
}

